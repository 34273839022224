.navbar {
  background: $primary-color;
  border: none;
  padding: 0 16px;
  z-index: 2;

  @media (min-width: 991px) {
    position: sticky;
    top: 0;
  }

  .navbar-nav {
    margin: auto;
    text-align: center;

    li {
      padding: 0 20px;
      margin: auto;

      a {
        color: #000;
        font-size: 22px;
        font-weight: 600;
        padding: 8px 10px;
        display: block;

        &:hover {
          background: #000;
          color: $primary-color;
          text-decoration: none;
        }
      }
    }
  }

  .dropdown-menu {
    background: $primary-color;
  }

  .dropdown:hover>.dropdown-menu,
  .dropdown-menu:hover {
    display: block;
  }

  @media (max-width: 768px) {
    #navbar .navbar-nav > li {
      display: block;
    }

    .navbar-nav .dropdown:hover > .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    .open>.dropdown-menu,
    .dropdown:hover > .dropdown-menu {
      margin: auto;
    }
  }

  @media (min-width: 991px) {
    .navbar-header {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .navbar-header {
      display: block;
      margin: auto;
    }

    .navbar-toggle {
      background: $primary-color;
      border: 1px solid #000;
      border-radius: 5px;
      padding: 5px 10px;
      margin: 10px 0;

      .icon-bar {
        background: #000;
        border-radius: 1px;
        display: block;
        height: 2px;
        margin: 5px 0;
        width: 22px;
      }

      &:hover {
        background-color: #000;

        .icon-bar {
          background-color: $primary-color;
        }
      }
    }
  }
}

// navbar font sizing
#navbar a {
  @media (max-width:1302px) {
    font-size: 16px;
  }

  @media (max-width:1229px) {
    font-size: 15px;
  }

  @media (max-width:1193px) {
    font-size: 14px;
  }

  @media (max-width:1156px) {
    font-size: 13px;
  }

  @media (max-width:1119px) {
    font-size: 12px;
  }

  @media (max-width:1083px) {
    font-size: 11px;
  }
}